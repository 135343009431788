const signalR = require("@microsoft/signalr");
const data = {
    myConnectionId:'',
    messages:[],
};
let counter = 0;
function signalRInfo(url,token){
    console.log(url,'连接地址')
    console.log(token)
    const options = {
        accessTokenFactory: () => token
      };
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(url,options)
        .configureLogging(signalR.LogLevel.Information)
        .build();
        connection.on('onMessage', onMessage);
        connection.on('onConnected', onConnected);
        connection.on('onDisconnected', onDisconnected);
        connection.on('connectionSlow', onConnectionSlow);
        connection.onclose(() => {
            console.log('close connection')
            connection.start()
            .then(() => console.log('re-connection!'))
            .catch(setTimeout(() => connection.start(), 5000));
           }
          );
          console.log('connecting...');
          connection.start()
          .then(() => console.log('connected!'))
          .catch(console.log('Start connect fail!!'));
}
function getAxiosConfig(userName,channel) {
    const config = {
      headers: { 'X-Auth-UserID': data.username,'X-Auth-Channel': data.channel }	  
    };
    return config;
  }
function sendMessage(sender, to, groupname, messageText,url) {
    return axios.post(url+`/messages`, {
      connectionId: data.myConnectionId,
      to: [to],
      isPrivate: to != null,
      groupname: groupname,
      sender: sender,
      text: JSON.stringify({ 
         MT: 'N',
         TM: Math.floor(Date.now()),
         PL: messageText,
      })
    }, getAxiosConfig()).then(resp => resp.data);
  }
  function sendAck(sender, to, groupname, timestamp) {
    return axios.post(`${data.channelApiUrl}/messages`, {
      connectionId: data.myConnectionId,
      to: [to],
      isPrivate: to != null,
      groupname: groupname,
      sender: sender,
      text: JSON.stringify({ 
         MT: 'A',
         TM: timestamp,
         TM2: Math.floor(Date.now())
      })
    }, getAxiosConfig()).then(resp => resp.data);
  }
function onMessage(message) {
    console.log(message, '收到消息!!!!')
    message.id = counter++; // vue transitions need an id
    message.Timestamp = Math.floor(Date.now())
    data.messages.unshift(message);
    //hostMessageHandler(message)
  };
  function onConnected(message) {
    data.myConnectionId = message.ConnectionId;
    //  message.id = counter++; // vue transitions need an id
    //  data.messages.unshift(message);
  }

  function onDisconnected(message) {
    data.myConnectionId = message.ConnectionId;
        message.id = counter++; // vue transitions need an id
    data.messages.unshift(message);
  }

  function onConnectionSlow(message) {
    console.log('Slow Connecgtion!')
  }

//   function hostMessageHandler(message) {
//      if(message.Sender) {
//       const m = JSON.parse(message.Text)
//       if(m.TM && m.MT=='N') sendAck(data.username, message.Sender, null, m.TM);
//      }
//     return(null)
//   }
export{signalRInfo,sendMessage,onConnected,data}
